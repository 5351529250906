/**
 * Boilerplate code for a new Redux-connected view component.
 * Nice for copy/pasting
 */

// import primary libraries
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import withRouter from 'react-router-dom/withRouter'

// import third-party libraries
import { Helmet } from 'react-helmet'

// import global components
import Binder from '../../../global/components/Binder.js.jsx'
import WorkspaceLayout from '../../client/practice/components/WorkspaceLayout.js.jsx'
import NotificationStaffClientForm from './NotificationStaffClientForm.js.jsx'

class NotificationWorkspaceLayout extends Binder {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const selectedClient = this.props.clientStore
      ? this.props.clientStore.selected.getItem()
      : null
    return (
      <WorkspaceLayout>
        <Helmet>
          <title>{selectedClient?.name ?? 'Workspace'} - Notifications</title>
        </Helmet>
        <NotificationStaffClientForm />
      </WorkspaceLayout>
    )
  }
}

NotificationWorkspaceLayout.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

NotificationWorkspaceLayout.defaultProps = {}

const mapStoreToProps = (store, props) => {
  return {
    clientStore: store.client,
  }
}

export default withRouter(connect(mapStoreToProps)(NotificationWorkspaceLayout))
