import React from 'react'
import {
  Button,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
  Stack,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { styled } from '@mui/material/styles'

const Div = styled('div')(({ theme }) => ({
  ...theme.typography.button,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(1),
}))

export default function AiAcknowledgePopup({
  open,
  handleClose,
  onAcknowledge,
}) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: 2,
          p: 1,
        },
      }}
    >
      <DialogTitle sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 2 }}>
        <Typography
          variant="h4"
          sx={{ fontWeight: 500 }}
        >
          AI Tools Terms and Conditions
        </Typography>
        <IconButton
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ p: 2 }}>
        <>
          <Typography
            variant="body1"
            sx={{ mb: 4 }}
          >
            Disclaimer:
            <Div />
            AI Services: The Services or portions thereof may incorporate or
            otherwise leverage artificial intelligence (“AI”) or similar
            technologies (collectively “AI Services”). The AI Services may, but
            do not necessarily, include deep learning, machine learning,
            generative artificial intelligence, large language models, and other
            artificial intelligence technologies, including any and all (a)
            proprietary algorithms, software or systems that make use of or
            employ neural networks, statistical learning algorithms (like linear
            and logistic regression, support vector machines, random forests,
            k-means clustering), or reinforcement learning, and (b) proprietary
            embodied AI and related hardware or equipment. The terms and
            conditions herein apply to any portion of the Services constituting
            or leveraging AI Services. The AI Services may enable users to
            create, modify, or enhance content based on various inputs and
            parameters.
            <Div />
            You represent and warrant that you have obtained all necessary
            consents and permissions from any third parties whose data,
            information, content, or intellectual property rights may be
            involved in or affected by the use of the AI Services or any data,
            information or content (collectively, “Content”) submitted to or
            generated by the Services. You agree to comply with all applicable
            laws, rules and regulations regarding such use and to respect and
            protect such rights.
            <Div />
            You grant Company a non-exclusive, royalty-free, worldwide,
            perpetual, irrevocable, sublicensable, transferable license to copy,
            modify, and otherwise use any Content submitted to or generated by
            the AI Services for the purpose of enhancing the performance and
            functionality of the Services and developing new products and
            services. You also agree that Company may collect and analyze data
            and feedback from your use of the AI Services for such purposes.
            <Div />
            All or a portion of the AI Services may be provided by a third
            party, and third-party terms may apply to your use of the AI
            Services. You agree to comply with any such third-party terms, and
            you authorize Company to transfer or otherwise provide such third
            parties with the Content submitted by you. You further acknowledge
            that such third parties are outside of Company’s control and may
            have access and rights to the Content you submit to or through the
            AI Services. Company shall have no responsibility or liability for,
            and you hereby hold Company harmless from, all acts and omissions of
            any such third-party. Further, Company cannot guarantee the
            availability of any third-party services, and therefore any AI
            Services dependent on such third-party services may be terminated or
            suspended at any time without notice, and in such event Company
            shall have no liability for such termination or suspension.
            <Div />
            You acknowledge that use of the AI Services may involve risks and
            uncertainties, such as unpredictable or unintended outcomes, bias,
            errors, failures, or liabilities. Summarization only works for files
            up to 5MB. Company does not represent, warrant or guarantee, and you
            hereby hold Company harmless from, the accuracy, quality,
            suitability, or legality of any Content generated by the AI
            Services, and you are solely responsible for verifying, reviewing,
            and approving such Content before using it for any purpose. You
            agree to assume all such risks and to indemnify and hold harmless
            Company from any claims, liabilities, losses, or damages arising
            from or relating to the use of the AI Services or any Content
            submitted to or generated by the AI Services, and no such claims,
            liabilities, losses, or damages shall be subject to, or limited by,
            any limitation of liability, limitation of remedy or disclaimer
            otherwise set forth in this agreement.
            <Div />
            COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT
            TO THE AI SERVICES AND ANY CONTENT GENERATED BY THE AI SERVICES,
            INCLUDING BUT NOT LIMITED TO WARRANTIES OF MERCHANTABILITY, FITNESS
            FOR A PARTICULAR PURPOSE, NON-INFRINGEMENT, AND QUALITY. COMPANY
            DOES NOT WARRANT THAT THE AI SERVICES WILL MEET YOUR REQUIREMENTS OR
            EXPECTATIONS, OR THAT IT WILL OPERATE WITHOUT INTERRUPTION OR ERROR.
            <Div />
            TO THE MAXIMUM EXTENT PERMITTED BY LAW, COMPANY'S LIABILITY FOR ANY
            BREACH OF THIS CONTRACT OR ANY OTHER CLAIM ARISING FROM OR RELATING
            TO THE AI SERVICES OR ANY CONTENT GENERATED BY THE AI SERVICES SHALL
            BE LIMITED TO ONE HUNDRED DOLLARS ($100). TO THE MAXIMUM EXTENT
            PERMITTED BY LAW, IN NO EVENT SHALL COMPANY BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, PUNITIVE, OR SPECIAL DAMAGES,
            OR FOR ANY LOSS OF PROFITS, REVENUE, DATA, OR GOODWILL IN RELATION
            TO THE AI SERVICES.
            <Div />
          </Typography>

          <Stack
            direction="row"
            spacing={1}
            mt={3}
            justifyContent="space-between"
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                handleClose()
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                onAcknowledge()
              }}
            >
              I understand, enable
            </Button>
          </Stack>
        </>
      </DialogContent>
    </Dialog>
  )
}
