import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Helmet } from 'react-helmet'
import * as clientPostActions from '../../clientPostActions'
import * as clientPostReplyActions from '../../../clientPostReply/clientPostReplyActions'
import * as clientActions from '../../../client/clientActions'
import * as firmActions from '../../../firm/firmActions'
import * as staffActions from '../../../staff/staffActions'
import * as userActions from '../../../user/userActions'
import TextAreaInput from '../../../../global/components/forms/TextAreaInput.js.jsx'
import ClientPostListItem from '../../components/ClientPostListItem.js.jsx'
import WorkspaceLayout from '../../../client/practice/components/WorkspaceLayout.js.jsx'
import { withRouter } from 'react-router'

const ClientPostPracticeList = ({
  dispatch,
  clientPostStore,
  clientStore,
  firmStore,
  defaultClientPost,
  loggedInUser,
  userStore,
  match,
  history,
}) => {
  const [clientPost, setClientPost] = useState(defaultClientPost.obj)

  const isArchivedView = Boolean(
    match.url && match.url.indexOf('archived') > -1,
  )

  useEffect(() => {
    dispatch(staffActions.fetchStaffLoggedInByFirmIfNeeded(match.params.firmId))
    dispatch(clientPostActions.fetchDefaultClientPost())
    dispatch(clientPostReplyActions.fetchDefaultClientPostReply())
    dispatch(firmActions.fetchSingleIfNeeded(match.params.firmId)).then(() => {
      dispatch(clientActions.fetchSingleIfNeeded(match.params.clientId)).then(
        () => {
          dispatch(
            clientPostActions.fetchListIfNeeded(
              '_firm',
              match.params.firmId,
              '_client',
              match.params.clientId,
              'status',
              isArchivedView ? 'archived' : 'active',
            ),
          )
          dispatch(
            userActions.fetchListIfNeeded('_firmStaff', match.params.firmId),
          )
          dispatch(
            userActions.fetchListIfNeeded('_client', match.params.clientId),
          )
          dispatch(
            clientPostReplyActions.fetchListIfNeeded(
              '_firm',
              match.params.firmId,
              '_client',
              match.params.clientId,
            ),
          )
        },
      )
    })
  }, [isArchivedView, match.params.clientId, match.params.firmId])

  useEffect(() => {
    if (!clientPost?.content) {
      setClientPost(defaultClientPost.obj)
    }
  }, [defaultClientPost])

  const handleFormChange = e => {
    let newClientPost = { ...clientPost }
    newClientPost[e.target.name] = e.target.value
    setClientPost(newClientPost)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    let newPost = { ...clientPost }
    newPost._firm = parseInt(match.params.firmId)
    newPost._client = parseInt(match.params.clientId)
    newPost._createdBy = loggedInUser._id

    dispatch(clientPostActions.sendCreateClientPost(newPost)).then(
      clientPostRes => {
        if (clientPostRes.success) {
          dispatch(
            clientPostActions.addClientPostToList(
              clientPostRes.item,
              '_firm',
              match.params.firmId,
              '_client',
              match.params.clientId,
              'status',
              isArchivedView ? 'archived' : 'active',
            ),
          )
          setClientPost(defaultClientPost.obj)
        } else {
          alert('ERROR - Check logs')
        }
      },
    )
  }

  const selectedFirm = firmStore.selected.getItem()
  const selectedClient = clientStore.selected.getItem()
  const clientPostListItems =
    selectedFirm &&
    selectedFirm._id &&
    selectedClient &&
    clientPostStore.util.getList(
      '_firm',
      selectedFirm._id,
      '_client',
      selectedClient._id,
      'status',
      isArchivedView ? 'archived' : 'active',
    )

  const clientPostList =
    (clientPostListItems &&
      clientPostStore.lists?._firm?.[selectedFirm._id]?._client?.[
        selectedClient._id
      ]) ||
    null

  const isEmpty =
    !clientPostListItems || !clientPostList || !selectedFirm || !selectedClient
  const isFetching =
    !clientPostListItems ||
    !clientPostList ||
    clientPostList.isFetching ||
    selectedFirm.isFetching ||
    selectedClient.isFetching

  return (
    <WorkspaceLayout>
      <Helmet>
        <title>{selectedClient?.name || 'Workspace'} - Message Board</title>
      </Helmet>
      <br />
      {isEmpty ? (
        isFetching ? (
          <h2>Loading...</h2>
        ) : (
          <h2>Empty.</h2>
        )
      ) : (
        <div style={{ opacity: isFetching ? 0.5 : 1 }}>
          <div className="-mob-layout-ytcol100 yt-row with-gutters space-between">
            <div className="yt-col _75 -message-list">
              {clientPost && !isArchivedView && (
                <div className="new-post">
                  <div className="-content">
                    <TextAreaInput
                      change={handleFormChange}
                      name="subject"
                      placeholder={'Subject...'}
                      value={clientPost.subject}
                      rows={'1'}
                    />
                  </div>
                  <div className="-content">
                    <TextAreaInput
                      change={handleFormChange}
                      name="content"
                      placeholder="Start a conversation..."
                      value={clientPost.content}
                    />
                  </div>
                  <div className="-footer">
                    <button
                      disabled={
                        !clientPost.content || !clientPost.content.trim()
                      }
                      className="yt-btn small success"
                      onClick={handleFormSubmit}
                    >
                      Send
                    </button>
                  </div>
                </div>
              )}
              {
                clientPostListItems.length > 0 ?
                clientPostListItems
                  .sort(
                    (a, b) => new Date(b.created_at) - new Date(a.created_at),
                  )
                  .map((clientPost, i) => (
                    <ClientPostListItem
                      key={`clientPost_${clientPost._id}_${i}`}
                      clientPost={clientPost}
                      createdBy={userStore.byId[clientPost._createdBy]}
                      loggedInUser={loggedInUser}
                      client={selectedClient}
                      firm={selectedFirm}
                      style={
                        isArchivedView && i === 0 ? { marginTop: '0px' } : {}
                      }
                    />
                  ))
                :
                <div>
                  <p>
                    <em>No messages found</em>
                  </p>
                </div>
              }
            </div>
            <div className="yt-col _25 -message-list">
              {isArchivedView ? (
                <button
                  type="button"
                  className="yt-btn small info"
                  onClick={() => {
                    history.push(
                      `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/messages`,
                    )
                  }}
                  title="View Active"
                >
                  View Messages
                </button>
              ) : (
                <button
                  type="button"
                  className="yt-btn small info"
                  onClick={() => {
                    history.push(
                      `/firm/${match.params.firmId}/workspaces/${match.params.clientId}/messages/archived`,
                    )
                  }}
                  title="View Archive"
                >
                  View Archive
                </button>
              )}
            </div>
          </div>
        </div>
      )}
    </WorkspaceLayout>
  )
}

ClientPostPracticeList.propTypes = {
  dispatch: PropTypes.func.isRequired,
}

const mapStoreToProps = store => ({
  clientPostStore: store.clientPost,
  clientStore: store.client,
  firmStore: store.firm,
  defaultClientPost: store.clientPost.defaultItem,
  loggedInUser: store.user.loggedIn.user,
  userStore: store.user,
})

export default withRouter(connect(mapStoreToProps)(ClientPostPracticeList))
