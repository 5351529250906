import React, { useRef, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import apiUtils from '../../utils/api'
import downloadsUtil from '../../utils/downloadsUtil'
import * as fileActions from '../../../resources/file/fileActions'

const stateSelector = state => {
	return state.file.zipperTasks
}

const ZipDownloader = ({ isOpen, openDetails }) => {
	const zipperTasks = useSelector(stateSelector)
	const intervalRef = useRef(null)
	const dispatch = useDispatch()

	console.log('tasks:', zipperTasks)

	useEffect(() => {
		if (!zipperTasks.length) {
			return
		}

		intervalRef.current = setInterval(() => {
			zipperTasks.forEach(task => {
				apiUtils.callAPI(`/api/files/zipper-task/${task.id}`).then(json => {
					if (!json.success) {
						return
					}

					if (json.zipperTask.status != 'pending') {
						// clear task
						dispatch(fileActions.finishZipperTask(task.id))
					}

					if (json.zipperTask.status == 'success') {
						// download archive
						downloadsUtil.downloadUrl(json.download_url, 'Files Zipped.zip')
					}

					if (json.zipperTask.status == 'failure') {
						alert('Failed to create zip archive.')
					}
				})
			})
		}, 1500)

		return () => {
			clearInterval(intervalRef.current)
			intervalRef.current = null
		}
	}, [zipperTasks])

	if (!zipperTasks.length) {
		return null
	}

	return (
		<div className="-zip-downloader">
			<div
				className="action-link"
				onClick={openDetails}
			>
				<i className="-icon fas fa-file-zipper" />
				<span className="-text">
					Creating {zipperTasks.length} zip
					{zipperTasks.length > 1 ? 's' : ''}
				</span>
			</div>
			<TransitionGroup>
				{isOpen ? (
					<CSSTransition
						classNames="dropdown-anim"
						timeout={250}
					>
						<div className="-details">
							{zipperTasks.map(task => (
								<div
									key={task.id}
									className="-file"
								>
									<i className="-icon fas fa-file-zipper" />
									<span>Zipping {task.fileCount} files...</span>
								</div>
							))}
							<span>
								This might take a while. If you close this page we will email
								you the zip when ready.
							</span>
						</div>
					</CSSTransition>
				) : null}
			</TransitionGroup>
		</div>
	)
}

export default ZipDownloader
