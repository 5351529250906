import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import CSSTransition from 'react-transition-group/CSSTransition'
import TransitionGroup from 'react-transition-group/TransitionGroup'

import classNames from 'classnames'
import { throttle } from 'lodash'

import CheckboxInput from '../../../global/components/forms/CheckboxInput.js.jsx'

const Modal = ({
  btnColor,
  cardSize,
  closeAction,
  closeText,
  confirmAction,
  confirmText,
  disableConfirm,
  fixed,
  fileGrid,
  headerStyle,
  isOpen,
  modalClasses,
  modalHeader,
  showButtons,
  showClose,
  showConfirm,
  progressDetail,
  progressRun,
  showExit,
  tcCheckboxAction,
  tcCheckboxStatus,
  showTermsConditionsCB,
  otherActions = null,
  children,
}) => {
  const toggleBodyClass = useCallback(
    throttle(open => {
      document.body.classList.toggle('modal-open', open)
    }, 500),
    [],
  )

  useEffect(() => {
    toggleBodyClass(isOpen)
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [isOpen, toggleBodyClass])

  const topClass = classNames('topbar')
  const confirmBtnClass = classNames('yt-btn small', btnColor)
  const closeBtnClass = classNames('yt-btn x-small', modalClasses)
  const linkBtnClass = classNames('yt-btn small', 'link', btnColor)

  const modalClass = classNames(modalClasses, {
    'standard-modal': !fixed,
    'fixed-modal': fixed,
    'file-grid-modal': fileGrid,
  })

  const colClass = classNames('yt-col', 'full', {
    's_75 m_40 l_25 xl_20': cardSize === 'small',
    's_75 m_50 l_33': !cardSize || cardSize === 'standard',
    's_75  l_50': cardSize === 'large',
    s_80: cardSize === 'jumbo',
    s_90: cardSize === 'jumbo_90',
  })

  return (
    <TransitionGroup>
      {isOpen && (
        <CSSTransition
          timeout={500}
          classNames="modal-anim"
        >
          <div className={modalClass}>
            <div className={colClass}>
              <div className="card">
                <div
                  className="card-header"
                  style={headerStyle}
                >
                  <div className="yt-row center-vert space-between">
                    {modalHeader}
                    {showExit && (
                      <button
                        className={closeBtnClass}
                        onClick={closeAction}
                      >
                        <i className="far fa-times" />
                      </button>
                    )}
                  </div>
                </div>
                <div className="card-body">{children}</div>
                {showButtons && (
                  <div className="card-footer">
                    <div className="yt-row space-between">
                      {closeText && showClose ? (
                        <button
                          type="button"
                          className={linkBtnClass}
                          onClick={closeAction}
                        >
                          {closeText}
                        </button>
                      ) : (
                        !showTermsConditionsCB && <div />
                      )}
                      {showTermsConditionsCB && (
                        <div>
                          <CheckboxInput
                            name="tc-cb"
                            value={tcCheckboxStatus}
                            change={tcCheckboxAction}
                            checked={tcCheckboxStatus}
                            label={'I agree to these Terms & Conditions'}
                          />
                          <p>
                            Read our full Terms & Conditions{' '}
                            <a
                              target="_blank"
                              href="https://mangopractice.com/terms-conditions/"
                              rel="noreferrer"
                            >
                              here
                            </a>
                            .
                          </p>
                        </div>
                      )}
                      <div>
                        {otherActions && otherActions}
                        {confirmAction && showConfirm ? (
                          <button
                            type="button"
                            className={confirmBtnClass}
                            onClick={() => confirmAction()}
                            disabled={disableConfirm}
                          >
                            {confirmText || 'Done'}
                          </button>
                        ) : (
                          <div />
                        )}
                      </div>
                    </div>
                    {progressRun && progressDetail.filename && (
                      <div className="upload-progress-container">
                        <p>
                          processing{' '}
                          {`${progressDetail.category ? (progressDetail.category === 'folder' ? 'file' : 'folder') : ''}:`}{' '}
                          {progressDetail.filename}...
                        </p>
                        <div
                          className={`progress-bar-${progressDetail.percent}`}
                        >
                          <div className="-progress">
                            <div className="-complete"></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </CSSTransition>
      )}
    </TransitionGroup>
  )
}

Modal.propTypes = {
  btnColor: PropTypes.string,
  cardSize: PropTypes.oneOf([
    'small',
    'standard',
    'large',
    'jumbo',
    'jumbo_90',
  ]),
  closeAction: PropTypes.func.isRequired,
  closeText: PropTypes.string,
  confirmAction: PropTypes.func,
  confirmText: PropTypes.any,
  disableConfirm: PropTypes.bool,
  fixed: PropTypes.bool,
  headerStyle: PropTypes.any,
  isOpen: PropTypes.bool.isRequired,
  modalHeader: PropTypes.any,
  modalClasses: PropTypes.string,
  showButtons: PropTypes.bool,
  showClose: PropTypes.bool,
  showConfirm: PropTypes.bool,
  showExit: PropTypes.bool,
  showTermsConditionsCB: PropTypes.bool,
  tcCheckboxStatus: PropTypes.bool,
  tcCheckboxAction: PropTypes.func,
}

Modal.defaultProps = {
  btnColor: 'info',
  cardSize: 'standard',
  closeText: 'Close',
  confirmAction: null,
  confirmText: null,
  disableConfirm: false,
  fixed: false,
  headerStyle: null,
  modalHeader: null,
  modalClasses: 'white link ',
  showButtons: true,
  showClose: true,
  showConfirm: true,
  showExit: true,
  showTermsConditionsCB: false,
  tcCheckboxStatus: false,
}

export default Modal
