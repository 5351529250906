import React, { useState } from 'react'
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid'
import {
  Box,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  Tooltip,
} from '@mui/material'
import ArchiveIcon from '@mui/icons-material/Archive'
import NotificationsIcon from '@mui/icons-material/Notifications'
import PeopleIcon from '@mui/icons-material/People'
import AssignmentIcon from '@mui/icons-material/Assignment'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import UnarchiveIcon from '@mui/icons-material/Unarchive'
import DeleteIcon from '@mui/icons-material/Delete'
import StarIcon from '@mui/icons-material/Star'
import StarBorderIcon from '@mui/icons-material/StarBorder'
import { Link } from 'react-router-dom'

const popperSettings = {
  modifiers: [
    {
      name: 'offset',
      options: {
        offset: [0, -16],
      },
    },
  ],
}

const staffActionListItems = [
  {
    label: 'Assign Staff',
    value: 'client_new_staff_client',
  },
  {
    label: 'Unassigned Staff',
    value: 'unassigned_staff',
  },
]

const notificationActionListItems = [
  {
    label: 'Client Notification',
    value: 'client_notification',
  },
  {
    label: 'Staff Notification',
    value: 'client_staff_notification',
  },
]

const getTemplateActionListItems = advancedPermissions =>
  advancedPermissions
    ? [
        {
          label: 'Apply Request List',
          value: 'request_list_apply',
        },
      ]
    : [
        {
          label: 'Apply Folder Template',
          value: 'file_folder_template_apply',
        },
        {
          label: 'Apply Request List',
          value: 'request_list_apply',
        },
      ]

const WorkspaceListToolbar = ({
  selectedClientId,
  setShowDeleteAlertModal,
  _handleSetStatus,
  isArchivedView,
  ownerPermissions,
  setRoleModal,
  setUnassignStaffModalOpen,
  advancedPermissions,
  firmId,
  hideExport,
  isFavorite,
  setIsFavorite,
  handleSearch,
  searchText,
}) => {
  const [notificationAnchorEl, setNotificationAnchorEl] = useState(null)
  const [staffAnchorEl, setStaffAnchorEl] = useState(null)
  const [templateAnchorEl, setTemplateAnchorEl] = useState(null)
  const [optionsAnchorEl, setOptionsAnchorEl] = useState(null)

  const toggleNotificationMenu = event => {
    setNotificationAnchorEl(event ? event.currentTarget : null)
  }

  const toggleStaffMenu = event => {
    setStaffAnchorEl(event ? event.currentTarget : null)
  }

  const toggleTemplateMenu = event => {
    setTemplateAnchorEl(event ? event.currentTarget : null)
  }

  const toggleOptionsMenu = event => {
    setOptionsAnchorEl(event ? event.currentTarget : null)
  }

  const _handleClientAction = value => {
    if (value === 'unassigned_staff') {
      setUnassignStaffModalOpen(true)
    } else {
      setRoleModal(value)
    }
  }

  const handleToggleFavorite = () => {
    setIsFavorite(!isFavorite)
  }

  const templateActionListItems =
    getTemplateActionListItems(advancedPermissions)

  return (
    <GridToolbarContainer
      sx={{
        overflowX: 'hidden',
        minHeight: 70,
        '@media (max-width:920px)': {
          minHeight: 140,
        },
        '@media (max-width:435px)': {
          overflowX: 'scroll',
        },
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <Tooltip
          title="Favorites"
          slotProps={{
            popper: popperSettings,
          }}
        >
          <IconButton onClick={handleToggleFavorite}>
            {isFavorite ? (
              <StarIcon color="secondary" />
            ) : (
              <StarBorderIcon color="disabled" />
            )}
          </IconButton>
        </Tooltip>
        {ownerPermissions && isArchivedView ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Tooltip
              title={`Reinstate Clients ${selectedClientId?.length || ''}`}
              slotProps={{
                popper: popperSettings,
              }}
            >
              <span>
                <IconButton
                  disabled={!selectedClientId.length}
                  onClick={() => {
                    _handleSetStatus('visible')
                  }}
                >
                  <UnarchiveIcon />
                </IconButton>
              </span>
            </Tooltip>
            <Tooltip
              title={`Delete Clients ${selectedClientId?.length || ''}`}
              slotProps={{
                popper: popperSettings,
              }}
            >
              <span>
                <IconButton
                  disabled={!selectedClientId.length}
                  onClick={() => {
                    setShowDeleteAlertModal(true)
                  }}
                >
                  <DeleteIcon />
                </IconButton>
              </span>
            </Tooltip>
            <div>
              <Tooltip title="Options">
                <span>
                  <IconButton onClick={toggleOptionsMenu}>
                    <MoreVertIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <Menu
                anchorEl={optionsAnchorEl}
                open={!!optionsAnchorEl}
                onClose={() => toggleOptionsMenu()}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={() => toggleOptionsMenu()}>
                  <Link to={`/firm/${firmId}/workspaces`}>View Clients</Link>
                </MenuItem>
              </Menu>
            </div>
          </div>
        ) : (
          ownerPermissions && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Tooltip
                title={`Archive Clients ${selectedClientId?.length || ''}`}
                slotProps={{
                  popper: popperSettings,
                }}
              >
                <span>
                  <IconButton
                    disabled={!selectedClientId.length}
                    onClick={() => {
                      _handleSetStatus('archived')
                    }}
                  >
                    <ArchiveIcon />
                  </IconButton>
                </span>
              </Tooltip>
              <div>
                <Tooltip
                  title={`Notification Setting ${selectedClientId?.length || ''}`}
                >
                  <span>
                    <IconButton
                      disabled={!selectedClientId.length}
                      onClick={toggleNotificationMenu}
                    >
                      <NotificationsIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={notificationAnchorEl}
                  open={!!notificationAnchorEl}
                  onClose={() => toggleNotificationMenu()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {notificationActionListItems.map(item => (
                    <MenuItem
                      key={item.value}
                      onClick={() => {
                        _handleClientAction(item.value)
                        toggleNotificationMenu()
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              <div>
                <Tooltip
                  title={`Staff Setting ${selectedClientId?.length || ''}`}
                >
                  <span>
                    <IconButton
                      disabled={!selectedClientId.length}
                      onClick={toggleStaffMenu}
                    >
                      <PeopleIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={staffAnchorEl}
                  open={!!staffAnchorEl}
                  onClose={() => toggleStaffMenu()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {staffActionListItems.map(item => (
                    <MenuItem
                      key={item.value}
                      onClick={() => {
                        _handleClientAction(item.value)
                        toggleStaffMenu()
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              <div>
                <Tooltip
                  title={`Apply Template ${selectedClientId?.length || ''}`}
                >
                  <span>
                    <IconButton
                      disabled={!selectedClientId.length}
                      onClick={toggleTemplateMenu}
                    >
                      <AssignmentIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={templateAnchorEl}
                  open={!!templateAnchorEl}
                  onClose={() => toggleTemplateMenu()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {templateActionListItems.map(item => (
                    <MenuItem
                      key={item.value}
                      onClick={() => {
                        _handleClientAction(item.value)
                        toggleTemplateMenu()
                      }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
              <div>
                <Tooltip title="Options">
                  <span>
                    <IconButton onClick={toggleOptionsMenu}>
                      <MoreVertIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Menu
                  anchorEl={optionsAnchorEl}
                  open={!!optionsAnchorEl}
                  onClose={() => toggleOptionsMenu()}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <MenuItem onClick={() => toggleOptionsMenu()}>
                    <Link to={`/firm/${firmId}/workspaces/archived`}>
                      View Archive
                    </Link>
                  </MenuItem>
                </Menu>
              </div>
            </div>
          )
        )}
        <TextField
          defaultValue={searchText}
          onChange={handleSearch}
          id="standard-basic"
          label="Search..."
          variant="standard"
          sx={{ minWidth: 100 }}
        />
      </div>
      <Box sx={{ flexGrow: 1 }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          minWidth: 300,
          maxHeight: 40,
        }}
      >
        <GridToolbarExport
          tooltipTitle="Export data"
          slotProps={{
            tooltip: {
              title: hideExport
                ? "You don't have permissions to export data"
                : 'Export data',
            },
            button: {
              variant: 'contained',
              color: 'secondary',
              disabled: hideExport,
            },
          }}
        />
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <br />
      </Box>
    </GridToolbarContainer>
  )
}

export default WorkspaceListToolbar
