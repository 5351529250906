import * as React from 'react'
import displayUtils from '../../../../global/utils/displayUtils'
import { Avatar, Tooltip, Typography } from '@mui/material'

export default function StaffAvatar({ name, overlap, tooltip }) {
  return (
    <Tooltip title={tooltip}>
      <Avatar
        sx={{
          bgcolor: displayUtils.getAvatarColor(name),
          width: 24,
          height: 24,
          marginLeft: overlap ? '-6px' : 0,
        }}
      >
        <Typography component="span">{name}</Typography>
      </Avatar>
    </Tooltip>
  )
}
